<template>
  <div class="flex-grow scroll-area">
    <div class="app-home">
      <div class="app-home-piece app-apply" v-if="list && list.length > 0">
        <div class="app-home-title">应用申请</div>
        <div class="piece-box inner-content">
          <div class="app-list">
            <div @click="goApp(item)" class="app-item" v-for="item in list" :key="item.id">
              <img :src="base + item.icon" class="app-icon">
              <div class="app-name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="app-home-piece user-apply-list">
        <div class="app-home-title">我的申请</div>
        <apply-list show-app-name :query="query" ref="applyList" class="flex-grow scroll-area piece-box inner-content">
          <template v-slot:query="{form}">
            <a-form-model-item label="应用类型">
              <a-select
                placeholder="选择应用筛选"
                :options="appOptions"
                v-model="form.temp_id"
                allowClear
                show-search
                option-filter-prop="children"
                @change="getList"
                style="width: 180px;"
              />
            </a-form-model-item>
          </template>
        </apply-list>
      </div>
    </div>
  </div>
</template>

<script>
    import {getUserWxId} from "../../common/js/storage";
    import {base} from "../../components/icon-select";
    import ApplyList from "../../layouts/ApplyList";
    import {announcement} from "../../common/constant/template";

    export default {
        name: "AppHome",
        components: {
            ApplyList
        },
        data() {
            return {
                base,
                list: [
                    announcement
                ],
                appOptions: [],
                query: ""
            }
        },
        created() {
            this.getAppList();
            this.query = `&filter[user_id]=${getUserWxId()}`
        },
        methods: {
            goApp(item) {
                let route = {name: 'AppApply', params: {id: item.id}}
                if(item.type == "announcement") {
                    route = {name: 'List', params: { type: "notice" }}
                }
                this.$router.push(route);
            },
            getList() {
                this.$refs.applyList.getList();
            },
            getAppList() {
                const url = `/admin/apply-temp?pageSize=1000&sort=order&filter[is_oa][neq]=2&filter[and][][visible_ids][fis]=${getUserWxId()}`;
                this.$axios(url).then(res => {
                   const list = [], options = [];
                    res.data.forEach(item => {
                        if(item.other_config) {
                            try {
                                const conf = JSON.parse(item.other_config);
                                if(conf.canAdminApply) {
                                    list.push(item);
                                }
                            } catch (e) {
                                console.error(e)
                            }
                        }
                        options.push({ value: item.id, label: item.name })
                    });
                    this.list.push(...list);
                    this.appOptions = options;
                   //  this.list = res.data;
                });
            }
        }
    }
</script>

<style lang="less">
.app-home {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1200px;
  height: 100%;
}
.app-home-title {
  margin-bottom: 10px;
}
.app-apply {
  margin-bottom: 16px;
}
.user-apply-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.app-list {
  display: flex;
  flex-wrap: wrap;
}
.app-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 10%;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 221, 165, 0.5)
  }
}
.app-icon {
  width: 54px;
  height: 54px;
  object-fit: contain;
}
.app-name {
  text-align: center;
}
</style>
